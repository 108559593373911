import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { ContainerLoader } from '../../components/loader';

import { RouteKeys } from './route-keys';
import AuthenticateRoute from '../auth/AuthenticateRoute';
import { State } from '../../store/interfaces';
import i18n from '../../i18n';

const Home = lazy(() => import('../home'));
const ForgotPassword = lazy(() => import('../forgot-password'));
const NotFound = lazy(() => import('../notfound'));
const SearchResult = lazy(() => import('../search-result'));
const Checkout = lazy(() => import('../checkout'));
const MyOrder = lazy(() => import('../my-order'));
const MyAccount = lazy(() => import('../my-account'));
const FAQ = lazy(() => import('../faq'));

const Offer = lazy(() => import('../offer'));
const BannerOffer = lazy(() => import('../banner-offer'));
const PrivacyPolicy = lazy(() => import('../privacy-policy'));
const TermsOfService = lazy(() => import('../terms-of-use'));
const BundleOffer = lazy(() => import('../bundle-offer-detail'));


interface CustomProps {
  lng?: string;
}

class Routes extends React.Component<CustomProps> {
  render() {
    const lng = `${this.props.lng}`;
    return (
      <Suspense
        fallback={
          <ContainerLoader
            height={500}
            text={i18n.t('Loading', { lng }) + '...'}
          />
        }
      >
        <Switch>
          <Route exact path={RouteKeys.Home} component={Home} />
          <Route exact path={RouteKeys.NotFound} component={NotFound} />
          <AuthenticateRoute
            exact
            path={RouteKeys.SearchResult}
            component={SearchResult}
          />
          <Route
            exact
            path={RouteKeys.ForgotPassword}
            component={ForgotPassword}
          />
          <AuthenticateRoute
            exact
            path={RouteKeys.Checkout}
            component={Checkout}
          />
          <AuthenticateRoute
            // exact
            path={RouteKeys.MyOrder}
            component={MyOrder}
          />
          <AuthenticateRoute exact path={RouteKeys.FAQ} component={FAQ} />
          <AuthenticateRoute path={RouteKeys.MyAccount} component={MyAccount} />

          <AuthenticateRoute path={RouteKeys.Offer} component={Offer} />
          <AuthenticateRoute
            path={RouteKeys.BannerOffer}
            component={BannerOffer}
          />
          <AuthenticateRoute
            // exact
            path={RouteKeys.BundleOffer}
            component={BundleOffer}
          />
          <Route
            exact
            path={RouteKeys.PrivacyPolicy}
            component={PrivacyPolicy}
          />
          <AuthenticateRoute
            exact
            path={RouteKeys.TermsOfService}
            component={TermsOfService}
          />
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    );
  }
}
const mapStateToProps = (state: State) => ({
  lng: state.authUser.languageKey,
  languageID: state.authUser.languageID,
});

export default connect(mapStateToProps, undefined)(Routes);
